module.exports = {
	network: {
		"400": "Request date is incorrect",
	},
	signIn: {
		"404": "The email or password you entered is incorrect.",
	},
	signUp: {
		"406": "An account with that email already exists.",
	},
	setCustomerPasswordByPasswordResetID: {
		"401": "This password reset key is expired",
		"404": "This password reset key is invalid",
	},
	getCustomerForgotPasswordLink: {
		"404": "No account found for that email address.",
	},
	purchase: require("./payment-errors.json"), // eslint-disable-line global-require
	updateSubscriptionByID: require("./payment-errors.json"), // eslint-disable-line global-require,
	changeCustomerPassword: {
		"404": "Current password is invalid",
	},
	changeCustomerEmail: {
		"404": "Current password is invalid",
		"406": "An account with that email already exists.",
	},
	removeActivationFromBlackList: {
		"404": "Activation is not deactivated",
	},
	addActivationToBlackList: {
		"404": "Activation is not deactivated",
	},
};
