/* eslint-disable global-require */

module.exports = {
	"400": require("./400.json"),
	"401": require("./401.json"),
	"403": require("./403.json"),
	"404": require("./404.json"),
	"500": require("./500.json"),
	"503": require("./503.json"),
};
