import React from "react";
import HomepageHero, {
	HomepageHeroBodyItemLink,
	HomepageHeroButtonDefault,
	HomepageHeroButtonSecondary,
	HomepageHeroHeadLink,
} from "@insightfulscience/atomic-react/r1/molecules/HomepageHero";
import HomeNewAndNoteworthy from "@insightfulscience/atomic-react/r1/molecules/HomeNewAndNoteworthy";
import { useLocale, WithLocale } from "@insightfulscience/i18";
import HomeResources from "@insightfulscience/atomic-react/r1/molecules/HomeResources";
import PageMeta from "@insightfulscience/smart-react/PageMeta";
import pipe from "@insightfulscience/shared-utils/fn/pipe";
import NextLink from "@insightfulscience/smart-react/NextLink";
import { HeroCol } from "@insightfulscience/atomic-react/Hero/Grid";
import WistiaVideo from "@insightfulscience/atomic-react/r2/WistiaVideo";
import SectionTryForFree from "@src/SectionTryForFree";
import HomePageTabs from "@src/HomePage/Tabs";
import WithServices from "@src/services";
import Video from "@insightfulscience/atomic-react/Video";
import resources from "../locales/home-page";
import SiteLayout from "../src/SiteLayout";

const Homepage = () => {
	const [t] = useLocale("home-page");
	return (
		<SiteLayout isBorderBottomNone>
			<PageMeta title={t("PAGE_TITLE")} description={t("META_DESCRIPTION")}>
				<PageMeta.OGP
					title={t("PAGE_TITLE")}
					description={t("META_DESCRIPTION")}
					locale={t("META_LOCALE")}
					type={t("META_TYPE")}
					url={t("META_URL")}
					image={t("META_IMAGE")}
					imageSecureUrl={t("META_IMAGE_SECURE")}
					siteName={t("META_SITE_NAME")}
					articlePublisher={t("META_ARTICLE_PUBLISHER")}
					isImageBaseUrl
				/>
				<PageMeta.Twitter
					title={t("PAGE_TITLE")}
					description={t("META_DESCRIPTION")}
					site={t("META_TWITTER_SITE")}
					card={t("META_TWITTER_CARD")}
					image={t("META_TWITTER_IMAGE")}
					creator={t("META_TWITTER_CREATOR")}
					isImageBaseUrl
				/>
				<PageMeta.Canonical href={t("META_CANONICAL")} />
				<PageMeta.Author content={t("META_AUTHOR")} />
			</PageMeta>
			<HomepageHero>
				<HomepageHero.Head>
					<HomepageHero.Head.Title>{t("TITLE")}</HomepageHero.Head.Title>
					<HomepageHero.Head.Description>{t("PAGE_DESCRIPTION")}</HomepageHero.Head.Description>
					<HomepageHero.Head.Buttons>
						<NextLink Component={HomepageHeroButtonDefault} href={t("TRY_SNAPGENE")}>
							{t("TRY_FOR_FRY_BTN")}
						</NextLink>
						<NextLink Component={HomepageHeroButtonSecondary} href={t("PRICING")}>
							{t("PRICING_BTN")}
						</NextLink>
					</HomepageHero.Head.Buttons>
					<HomepageHero.Head.Links>
						<HomepageHeroHeadLink href={t("NEW_IN_SNAPGENE_LINK_HREF")}>
							{t("NEW_IN_SNAPGENE_LINK")}
						</HomepageHeroHeadLink>{" "}
						|{" "}
						<HomepageHeroHeadLink href="/features">
							{t("CORONOVIRUS_RESOURCES_LINK")}
						</HomepageHeroHeadLink>
					</HomepageHero.Head.Links>
					<HeroCol md={12} spacing={{ mt: 10, mb: 20 }}>
						<WistiaVideo>
							<Video
								srcMP4="/images/snapgene/homepage/homepage-Video.mp4"
								poster="/images/snapgene/homepage/homepage-Video-poster.png"
								preload
							/>
						</WistiaVideo>
					</HeroCol>
				</HomepageHero.Head>
				<HomepageHero.Body>
					<HomepageHero.Body.Item
						numItem={1}
						title={t("DESIGN_BETTER_PROCEDURES_TITLE")}
						description={t("DESIGN_BETTER_PROCEDURES_DESC")}
						srcImg={t("DESIGN_BETTER_PROCEDURES_IMAGE")}
						href={t("DESIGN_BETTER_PROCEDURES_HREF")}
					>
						<HomepageHeroBodyItemLink href={t("DESIGN_BETTER_PROCEDURES_HREF")}>
							{t("LEARN_MORE")}
						</HomepageHeroBodyItemLink>
					</HomepageHero.Body.Item>
					<HomepageHero.Body.Item
						numItem={2}
						title={t("VISUALIZE_YOUR_PROCESS_TITLE")}
						description={t("VISUALIZE_YOUR_PROCESS_DESC")}
						srcImg={t("VISUALIZE_YOUR_PROCESS_IMAGE")}
						href={t("VISUALIZE_YOUR_PROCESS_HREF")}
					>
						<HomepageHeroBodyItemLink href={t("VISUALIZE_YOUR_PROCESS_HREF")}>
							{t("LEARN_MORE")}
						</HomepageHeroBodyItemLink>
					</HomepageHero.Body.Item>
					<HomepageHero.Body.Item
						numItem={3}
						title={t("RECORD_YOUR_WORK_TITLE")}
						description={t("RECORD_YOUR_WORK_DESC")}
						srcImg={t("RECORD_YOUR_WORK_IMAGE")}
						href={t("RECORD_YOUR_WORK_HREF")}
					>
						<HomepageHeroBodyItemLink href={t("RECORD_YOUR_WORK_HREF")}>
							{t("LEARN_MORE")}
						</HomepageHeroBodyItemLink>
					</HomepageHero.Body.Item>
				</HomepageHero.Body>
			</HomepageHero>

			<HomeNewAndNoteworthy>
				<HomeNewAndNoteworthy.Title>{t("NEW_AND_NOTEWORTHY_TITLE")}</HomeNewAndNoteworthy.Title>
				<HomeNewAndNoteworthy.List>
					<HomeNewAndNoteworthy.List.Item
						id="home-news-academy"
						title={t("EXPLORE_SNAPGENE_ACADEMY_TITLE")}
						srcImg={t("EXPLORE_SNAPGENE_ACADEMY_IMAGE")}
						href={t("EXPLORE_SNAPGENE_ACADEMY_HREF")}
					>
						{t("EXPLORE_SNAPGENE_ACADEMY_DESC")}
					</HomeNewAndNoteworthy.List.Item>
					<HomeNewAndNoteworthy.List.Item
						id="home-news-release-notes"
						title={t("NEW_IN_SNAPGENE_TITLE")}
						srcImg={t("NEW_IN_SNAPGENE_IMAGE")}
						href={t("NEW_IN_SNAPGENE_HREF")}
					>
						{t("NEW_IN_SNAPGENE_DESC")}
					</HomeNewAndNoteworthy.List.Item>
					<HomeNewAndNoteworthy.List.Item
						id="home-news-coronavirus-resources"
						title={t("CORONAVIRUS_RESOURCES_TITLE")}
						srcImg={t("CORONAVIRUS_RESOURCES_IMAGE")}
						href={t("CORONAVIRUS_RESOURCES_HREF")}
					>
						{t("CORONAVIRUS_RESOURCES_DESC")}
					</HomeNewAndNoteworthy.List.Item>
				</HomeNewAndNoteworthy.List>
			</HomeNewAndNoteworthy>

			<HomeResources>
				<HomeResources.Head title={t("RESOURCES_TITLE")} />
				<HomeResources.Body>
					<HomeResources.Body.Item
						title={t("PLASMID_FILES_TITLE")}
						srcImg={t("PLASMID_FILES_IMAGE")}
						href={t("PLASMID_FILES_HREF")}
					>
						{t("PLASMID_FILES_DESC")}
					</HomeResources.Body.Item>
					<HomeResources.Body.Item
						title={t("TUTORIAL_VIDEOS_TITLE")}
						srcImg={t("TUTORIAL_VIDEOS_IMAGE")}
						href={t("TUTORIAL_VIDEOS_HREF")}
					>
						{t("TUTORIAL_VIDEOS_DESC")}
					</HomeResources.Body.Item>
					<HomeResources.Body.Item
						title={t("USER_GUIDE_TITLE")}
						srcImg={t("USER_GUIDE_IMAGE")}
						href={t("USER_GUIDE_HREF")}
					>
						{t("USER_GUIDE_DESC")}
					</HomeResources.Body.Item>
					<HomeResources.Body.Item
						title={t("SNAPGENE_VIEWER_TITLE")}
						srcImg={t("SNAPGENE_VIEWER_IMAGE")}
						href={t("SNAPGENE_VIEWER_HREF")}
					>
						{t("SNAPGENE_VIEWER_DESC")}
					</HomeResources.Body.Item>
				</HomeResources.Body>
			</HomeResources>

			<HomePageTabs active="1" />
			<SectionTryForFree />
		</SiteLayout>
	);
};

export default pipe(Homepage, WithLocale({ resources }), WithServices);
