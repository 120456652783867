/* eslint-disable global-require */
const { en: errors } = require("../../errors");

module.exports = {
	...errors,
	header: require("./header.json"),
	footer: require("./footer.json"),
	graphql: require("./graphql.js"),
	common: require("./common.json"),
	links: require("./links.json"),
	"payment-errors": require("./payment-errors.json"),
};
