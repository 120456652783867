import React from "react";
import { string } from "prop-types";
import Tabs from "@insightfulscience/atomic-react/Tabs";
import { useLocale } from "@insightfulscience/i18";
import BlockQuote, {
	BlockQuoteItem,
	BlockQuoteThumb,
} from "@insightfulscience/atomic-react/r1/molecules/BlockQuote";
import { Col } from "@insightfulscience/atomic-react/Grid";
import SmartTabs from "@insightfulscience/atomic-react/utils/SmartTabs";

function HomePageTabs({ active }) {
	const [t] = useLocale("home-page");

	return (
		<BlockQuote>
			<BlockQuote.Head>
				<SmartTabs initialActiveTab={active}>
					<SmartTabs.TabBody.Mounted className="width-100" id="1" Component={Col}>
						<BlockQuoteItem
							active
							author={t("BLOCK_QUOTE_FIRST_BLOCK_AUTHOR_1")}
							institution={t("BLOCK_QUOTE_FIRST_BLOCK_INSTITUTION_1")}
						>
							{t("BLOCK_QUOTE_FIRST_BLOCK_DESCRIPTION_1")}
						</BlockQuoteItem>
					</SmartTabs.TabBody.Mounted>

					<SmartTabs.TabBody.Mounted id="2" className="width-100" Component={Col}>
						<BlockQuoteItem
							active
							author={t("BLOCK_QUOTE_FIRST_BLOCK_AUTHOR_2")}
							institution={t("BLOCK_QUOTE_FIRST_BLOCK_INSTITUTION_2")}
						>
							{t("BLOCK_QUOTE_FIRST_BLOCK_DESCRIPTION_2")}
						</BlockQuoteItem>
					</SmartTabs.TabBody.Mounted>

					<SmartTabs.TabBody.Mounted id="3" className="width-100" Component={Col}>
						<BlockQuoteItem
							active
							author={t("BLOCK_QUOTE_FIRST_BLOCK_AUTHOR_3")}
							institution={t("BLOCK_QUOTE_FIRST_BLOCK_INSTITUTION_3")}
						>
							{t("BLOCK_QUOTE_FIRST_BLOCK_DESCRIPTION_3")}
						</BlockQuoteItem>
					</SmartTabs.TabBody.Mounted>

					<SmartTabs.TabBody.Mounted id="4" className="width-100" Component={Col}>
						<BlockQuoteItem
							active
							author={t("BLOCK_QUOTE_FIRST_BLOCK_AUTHOR_4")}
							institution={t("BLOCK_QUOTE_FIRST_BLOCK_INSTITUTION_4")}
						>
							{t("BLOCK_QUOTE_FIRST_BLOCK_DESCRIPTION_4")}
						</BlockQuoteItem>
					</SmartTabs.TabBody.Mounted>

					<SmartTabs.TabBody.Mounted id="5" className="width-100" Component={Col}>
						<BlockQuoteItem
							active
							author={t("BLOCK_QUOTE_FIRST_BLOCK_AUTHOR_5")}
							institution={t("BLOCK_QUOTE_FIRST_BLOCK_INSTITUTION_5")}
						>
							{t("BLOCK_QUOTE_FIRST_BLOCK_DESCRIPTION_5")}
						</BlockQuoteItem>
					</SmartTabs.TabBody.Mounted>

					<Tabs>
						<SmartTabs.Tab
							className="mr-6"
							id="1"
							href="#"
							cell="medium-4"
							src={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_1")}
							alt={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_1_ALT")}
							Component={BlockQuoteThumb}
						/>

						<SmartTabs.Tab
							className="mr-6"
							id="2"
							href="#"
							cell="medium-4"
							src={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_2")}
							alt={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_2_ALT")}
							Component={BlockQuoteThumb}
						/>

						<SmartTabs.Tab
							className="mr-6"
							id="3"
							href="#"
							cell="medium-4"
							src={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_3")}
							alt={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_3_ALT")}
							Component={BlockQuoteThumb}
						/>

						<SmartTabs.Tab
							className="mr-6"
							id="4"
							href="#"
							cell="medium-4"
							src={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_4")}
							alt={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_4_ALT")}
							Component={BlockQuoteThumb}
						/>

						<SmartTabs.Tab
							id="5"
							href="#"
							cell="medium-4"
							src={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_5")}
							alt={t("BLOCK_QUOTE_SECOND_BLOCK_IMAGE_5_ALT")}
							Component={BlockQuoteThumb}
						/>
					</Tabs>
				</SmartTabs>
			</BlockQuote.Head>
		</BlockQuote>
	);
}

HomePageTabs.propTypes = {
	active: string.isRequired,
};

export default HomePageTabs;
